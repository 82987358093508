import empty from "empty";
import React from "react";
import { Link } from "react-router";

/**
 * Activate React component
 * @return {React Element}
 */
const Activate = ({ user = empty.object, result = empty.object, refLists }) => {
  if (user.active && result.success === true) {
    return (
      <div>
        <h1>{refLists.getText("Activeren")}</h1>
        <p>Beste {user.name},</p>
        <p>
          {refLists.getText("Uw account is geactiveerd.")}. U kunt nu{" "}
          <Link to="/inloggen">inloggen</Link>.
        </p>
      </div>
    );
  } else if (result.success === false) {
    return (
      <div>
        <h1>{refLists.getText("Activeren")}</h1>
        <p>{refLists.getText("Activeren is niet gelukt.")}</p>
      </div>
    );
  }

  return null;
};

export default Activate;
