import classNames from "classnames";
import compose from "recompose/compose";
import empty from "empty";
import mapProps from "recompose/mapProps";
import React from "react";
import withState from "recompose/withState";
import { formatUtcDate, preventDefault } from "../utils";
import { Link } from "react-router";
import { orderBy } from "lodash";

const ActionButtons = compose(
  withState("showConfirm", "toggleConfirm", false),
  mapProps(
    ({ account, showConfirm, toggleConfirm, deleteAccount, ...rest }) => ({
      toggle: e => {
        e.preventDefault();
        toggleConfirm(!showConfirm);
      },
      deleteIt: e => {
        e.preventDefault();
        deleteAccount(account.id);
      },
      account,
      showConfirm,
      ...rest
    })
  )
)(({ showConfirm, account, toggle, deleteIt, refLists }) => (
  <div>
    <Link
      className="button active"
      title={refLists.getText("Bewerken")}
      to={`/admin/accounts/${account.id}`}
    >
      <i className="fa fa-fw fa-pencil" aria-hidden="true" />
    </Link>
    <a
      href="."
      className={classNames("button", { active: !account.isAdmin })}
      title={refLists.getText("Verwijderen")}
      onClick={account.isAdmin ? preventDefault : toggle}
    >
      <i className="fa fa-fw fa-times" aria-hidden="true" />
    </a>
    {showConfirm && (
      <div className="confirm-dialog">
        <p>
          {refLists.getText("Weet u het zeker?")}
          <br />
          <a href="." onClick={deleteIt}>
            {refLists.getText("Ja")}
          </a>
          <span> - </span>
          <a href="." onClick={toggle}>
            {refLists.getText("Nee")}
          </a>
        </p>
      </div>
    )}
    <Link
      className="button active"
      title={refLists.getText("Beoordelingen bekijken")}
      to={`/admin/accounts/${account.id}/results`}
    >
      <i className="fa fa-fw fa-eye" aria-hidden="true" />
    </Link>
  </div>
));

const UserRow = ({ account, refLists, deleteAccount, getResults }) => (
  <tr className={classNames({ inactive: !account.active })} key={account.id}>
    <td className="name" title={account.name}>
      {account.name}
    </td>
    <td className="email">
      <a
        title={refLists.getText("Mail deze gebruiker")}
        href={`mailto:${account.email}`}
      >
        {account.email}
      </a>
    </td>
    <td className="datetime">{formatUtcDate(account.registration)}</td>
    <td className="datetime">{formatUtcDate(account.lastLogin)}</td>
    <td className="results">{account.results}</td>
    <td className="action-buttons">
      <ActionButtons
        refLists={refLists}
        account={account}
        deleteAccount={deleteAccount}
        getResults={getResults}
      />
    </td>
  </tr>
);

/**
 * Admin React component
 * @return {React Element}
 */
const Admin = ({
  children,
  user = empty.object,
  accounts: { list = empty.object, filtered = empty.array } = empty.object,
  refLists,
  setFilter,
  deleteAccount,
  getResults
}) => {
  if (user.isAdmin && children) {
    return children;
  } else if (user.isAdmin) {
    return (
      <div>
        <h1>{refLists.getText("Beheer")}</h1>
        <div className="formField typeTextField">
          <label htmlFor="q">
            {refLists.getText("Zoek in de lijst van gebruikers")}
          </label>
          <div className="input">
            <input
              name="q"
              id="q"
              type="text"
              required={true}
              placeholder={refLists.getText("Trefwoord")}
              onChange={setFilter}
            />
          </div>
        </div>
        <table className="overview">
          <thead>
            <tr>
              <th className="name">{refLists.getText("Naam")}</th>
              <th className="email">{refLists.getText("E-mailadres")}</th>
              <th className="datetime">
                {refLists.getText("Registratie datum")}
              </th>
              <th className="datetime">{refLists.getText("Laatste inlog")}</th>
              <th className="status">{refLists.getText("Beoordelingen")}</th>
              <th className="action-buttons" />
            </tr>
          </thead>
          <tbody className="inspections-table-body">
            {orderBy(filtered, [id => list[id].name]).map(id => {
              const account = list[id];
              return (
                account && (
                  <UserRow
                    key={id}
                    account={account}
                    refLists={refLists}
                    deleteAccount={deleteAccount}
                    getResults={getResults}
                  />
                )
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  return null;
};

export default Admin;
