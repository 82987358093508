import config from "../config";
import empty from "empty";
import fetch from "isomorphic-fetch";

const fetchDefaultConfig = {
  headers: {
    "Content-type": "application/json",
    Accept: "application/json"
  },
  credentials: "include",
  mode: "cors"
};

class FetchError extends Error {
  constructor({ message = "Fetch error", status, url } = empty.object) {
    super(message);
    this.status = status;
    this.url = url;
  }
}
export { FetchError };

/**
 * Fetches the URL
 * @param  {string} relUrl relative url to the endpoint
 * @param  {object} fetchConfig specific for this fetch
 * @param  {Array<key, value>} headers array with query-string parameters
 */
const fetchJSON = async (
  relUrl,
  fetchConfig = empty.object,
  headers = empty.array
) => {
  // construct config
  const composedConfig = { ...fetchDefaultConfig, ...fetchConfig };

  // construct headers
  const auth =
    composedConfig.credentials === "include" && sessionStorage.getItem("auth");
  const composedHeaders = {
    ...fetchDefaultConfig.headers,
    ...(auth ? { Authorization: `Basic ${auth}` } : empty.object),
    ...(fetchConfig.headers || empty.object),
    ...headers.reduce(
      (o, { key, value }) => ({ ...o, [key]: value }),
      empty.object
    )
  };

  const url = `${config.apiPrefix}${relUrl}`;
  const response = await fetch(url, {
    ...composedConfig,
    headers: composedHeaders
  });
  if (response.status >= 200 && response.status < 300) {
    if (
      `${composedHeaders["Authorization"]}`.indexOf("Basic ") === 0 &&
      composedConfig.credentials === "include"
    ) {
      sessionStorage.setItem(
        "auth",
        composedHeaders["Authorization"].substring(6)
      );
    }

    if (response.status === 204) {
      // no content
      return { success: true };
    }

    return response.json();
  } else {
    throw new FetchError({
      message: response.statusText,
      status: response.status,
      url: response.url
    });
  }
};

/**
 * Gets the URL
 * @param  {string} url full url to the endpoint
 * @param  {Array<key, value>} params array with query-string parameters
 * @param  {Array<key, value>} headers array with query-string parameters
 */
export const getJSON = async (
  url,
  params = empty.array,
  headers = empty.array
) => {
  const query = params
    .map(
      ({ key, value }) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return fetchJSON(query ? `${url}?${query}` : url, { method: "GET" }, headers);
};

/**
 * Posts the URL
 * @param  {string} url full url to the endpoint
 * @param  {Array<key, value>} headers array with query-string parameters
 * @param  {object} body object
 */
export const postJSON = async (
  url,
  body = empty.object,
  headers = empty.array
) => fetchJSON(url, { method: "POST", body: JSON.stringify(body) }, headers);
