import compose from "recompose/compose";
import pure from "recompose/pure";
import ResetPassword from "../components/resetPassword.jsx";
import { connect } from "react-redux";
import { resetPasswordAction } from "../actions";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = ({ form: { result } }) => ({
  result
});

/**
 * Map dispatch to props
 */
const mapDispatch = dispatch => ({
  submit: form => dispatch(resetPasswordAction(form))
});

/**
 * Compose Higher-order component
 */
export default compose(
  pure,
  withRefLists("resetPassword:"),
  connect(mapState, mapDispatch)
)(ResetPassword);
