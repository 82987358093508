import { getJSON, postJSON } from "./utils";

const get = async id =>
  getJSON(id ? `/api/afval/results/${id}` : "/api/afval/results");

const stats = async year => getJSON(`/api/afval/results/stats/${year}`);

const deleteResult = async id => postJSON("/api/afval/results", { id });

export default {
  deleteResult,
  get,
  stats
};
