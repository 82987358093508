import AccountResults from "../components/accountResults.jsx";
import compose from "recompose/compose";
import empty from "empty";
import pure from "recompose/pure";
import { connect } from "react-redux";
import { formatUtcDate, getStatus } from "../utils";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = (
  {
    data: {
      accounts: { list = empty.array } = empty.object,
      results = empty.object,
      forms = empty.object
    },
    ui: { viewport = empty.object }
  },
  { params: { id = 0 } = empty.object }
) => {
  const resultList = results[`afval:${id}`]
    ? results[`afval:${id}`].list
    : empty.array;

  return {
    results: resultList.map(r => {
      const type =
        forms.list && forms.list[r.formId]
          ? forms.list[r.formId].label
          : `Formulier ${r.formId}`;

      const name = `${type} ${formatUtcDate(r.created)}`;
      const status = getStatus(r);

      return {
        name,
        status,
        result: "Onbeslist",
        ...r
      };
    }),
    account: list[id],
    viewport
  };
};

/**
 * Compose Higher-order component
 */
export default compose(
  pure,
  connect(mapState),
  withRefLists("account:")
)(AccountResults);
