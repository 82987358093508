// Babel polyfill
import "babel-polyfill";

// MomentJS
import moment from "moment";

// React
import React from "react";
import { render } from "react-dom";

// Redux
import store from "./store";
import { Provider } from "react-redux";

// Router
import { Router, hashHistory } from "react-router";

// Routes
import routes from "./routes";

moment.locale("nl");

// Render all components/routes
render(
  <Provider store={store}>
    <Router history={hashHistory} routes={routes} />
  </Provider>,
  document.getElementById("root")
);
