import compose from "recompose/compose";
import empty from "empty";
import lifecycle from "recompose/lifecycle";
import moment from "moment";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import Stats from "../components/stats.jsx";
import { connect } from "react-redux";
import { isLoggedIn } from "../routes";
import { statsAction } from "../actions";
import { withRefLists } from "../referenceLists.js";

const currentYear = parseInt(moment().format("Y"), 10);

/**
 * Map state to props
 */
const mapState = (
  { data: { stats = empty.object }, session: { user } },
  { params: { year = currentYear } = empty.object }
) => ({
  stats: stats[year],
  user,
  year
});

/**
 * Map dispatch to props
 */
const mapDispatch = (dispatch, props) => ({
  init: () => {
    const year = props.params.year
      ? parseInt(props.params.year, 10)
      : currentYear;
    if (isLoggedIn()) {
      dispatch(statsAction(year));
    }
  }
});

/**
 * Compose Higher-order component
 */
export default compose(
  onlyUpdateForKeys(["params", "stats"]),
  connect(mapState, mapDispatch),
  lifecycle({
    componentWillMount: function() {
      this.props.init();
    },
    componentWillReceiveProps: function(nextProps) {
      if (this.props.year !== nextProps.year) {
        nextProps.init();
      }
    }
  }),
  withRefLists("stats:")
)(Stats);
