import assign from "object-assign";
import debounce from "lodash/debounce";
import empty from "empty";

export class Viewport {
  /**
   */
  static get size() {
    const windowSize = window
      ? {
          height: window.innerHeight,
          width: window.innerWidth
        }
      : empty.object;

    const documentSize =
      document && document.documentElement
        ? {
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth
          }
        : empty.object;

    return assign(documentSize, {
      desktop: !(windowSize.width < 990)
    });
  }
}

export const resize = (() => {
  let latestDispatch;
  const measure = () =>
    latestDispatch({
      type: "RESIZE_MEASURE",
      viewport: Viewport.size
    });

  const lazyMeasure = debounce(measure, 100);
  return () => dispatch => {
    latestDispatch = dispatch;
    lazyMeasure();
  };
})();
