import React from "react";
import Header from "./header.jsx";

/**
 * App root React component
 * @return {React Element}
 */
const App = ({ children, user, logout, refLists, tabs }) => {
  return (
    <div className="container">
      <div className="page-wrapper">
        <Header user={user} logout={logout} refLists={refLists} tabs={tabs} />
        <main>
          <div className="row">
            <div className="content">{children}</div>
          </div>
        </main>
        <footer>
          <div className="logo-bottom" />
        </footer>
      </div>
    </div>
  );
};

export default App;
