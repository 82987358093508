import classNames from "classnames";
import empty from "empty";
import { map } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";
import { Link, withRouter } from "react-router";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import setPropTypes from "recompose/setPropTypes";
import logo from "../images/logo-ministerie-van-infrastructuur-en-waterstaat.png";

const MenuLink = compose(
  setPropTypes({
    children: PropTypes.any,
    className: PropTypes.string,
    to: PropTypes.string.isRequired
  }),
  withRouter,
  mapProps(({ to, router = empty.object, ...rest }) => ({
    to,
    isActive: router.isActive(to, false),
    ...rest
  }))
)(({ children, className, isActive = false, onClick, to }) => (
  <li className={classNames(className, { current: isActive })}>
    <Link onClick={onClick} to={to}>
      {children}
    </Link>
  </li>
));

const Header = ({ user, logout, refLists, tabs = empty.array }) => (
  <header>
    <div className="rijksoverheid-branding">
      <span>
        <img
          src={logo}
          alt={refLists.getText("Ministerie van Infrastructuur en Waterstaat")}
        />
      </span>
    </div>
    <div className="app-name">
      <span className="app-title">
        {user.id && (
          <span className="push-right">
            {refLists.getText("Welkom")} {user.name}
          </span>
        )}
        <span className="pull-left">{refLists.getText("Is het afval?")}</span>
      </span>
    </div>
    <nav className="main-navigation ng-cloak">
      <ul className="sf-menu sf-navbar">
        {user.id && <MenuLink to="/home">{refLists.getText("Home")}</MenuLink>}
        {!user.id && (
          <MenuLink to="/inloggen">{refLists.getText("Inloggen")}</MenuLink>
        )}
        {!user.id && (
          <MenuLink to="/registreren">
            {refLists.getText("Registreren")}
          </MenuLink>
        )}
        {tabs &&
          map(key => (
            <MenuLink to={`/tab/${encodeURIComponent(key)}`} key={key}>
              {refLists.getText(key)}
            </MenuLink>
          ))(tabs)}
        {user.id && user.isAdmin && (
          <MenuLink to="/admin">{refLists.getText("Beheer")}</MenuLink>
        )}
        {user.id && user.isAdmin && (
          <MenuLink to="/stats">{refLists.getText("Statistieken")}</MenuLink>
        )}
        {user.id && (
          <MenuLink onClick={logout} to="/inloggen">
            {refLists.getText("Uitloggen")}
          </MenuLink>
        )}
      </ul>
    </nav>
  </header>
);

export default compose(
  setPropTypes({
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string
    }).isRequired,
    logout: PropTypes.func.isRequired
  })
)(Header);
