import React from "react";
import { IndexRedirect, Route } from "react-router";
import {
  allAccountsAction,
  formCatalogAction,
  formResultsAction,
  referenceListsAction,
  resetFormAction
} from "./actions";
import Account from "./containers/account.jsx";
import AccountResults from "./containers/accountResults.jsx";
import Activate from "./containers/activate.jsx";
import Admin from "./containers/admin.jsx";
import App from "./containers/app.jsx";
import Authenticated from "./containers/authenticated.jsx";
import ChangePassword from "./containers/changePassword.jsx";
import Home from "./containers/home.jsx";
import Login from "./containers/login.jsx";
import Register from "./containers/register.jsx";
import ResetPassword from "./containers/resetPassword.jsx";
import Stats from "./containers/stats.jsx";
import Tab from "./containers/tab.jsx";
import store from "./store";

/**
 * Are you logged in
 */
export const isLoggedIn = () => {
  const state = store.getState();
  return state.session && state.session.user && state.session.user.id;
};

/**
 * Reset the form state
 */
const resetForm = (nextState, replace, callback) => {
  store.dispatch(resetFormAction());
  callback();
};

/**
 * Describes all requirements for the main application
 */
const requires = (nextState, replace, callback) => {
  store.dispatch(referenceListsAction());
  callback();
};

/**
 * The route requires the formresults
 */
const requireFormResults = ({ params }, replace, callback) => {
  if (isLoggedIn()) {
    store.dispatch(formCatalogAction());

    if (params && params.id) {
      store.dispatch(formResultsAction(params.id));
    } else {
      store.dispatch(formResultsAction());
    }
  }

  callback();
};

/**
 * Get all users
 */
const requireUsers = (nextState, replace, callback) => {
  store.dispatch(resetFormAction());

  if (isLoggedIn()) {
    store.dispatch(allAccountsAction());
  }

  callback();
};

/**
 * All routes
 */
const routes = (
  <Route path="/" component={App} onEnter={requires}>
    <Route component={Authenticated}>
      <IndexRedirect to="home" />
      <Route path="home" component={Home} onEnter={requireFormResults} />
      <Route path="admin" component={Admin} onEnter={requireUsers}>
        <Route path="accounts/:id" component={Account} onEnter={resetForm}>
          <Route
            path="results"
            component={AccountResults}
            onEnter={requireFormResults}
          />
        </Route>
      </Route>
      <Route path="stats" component={Stats}>
        <Route path=":year" component={Stats} />
      </Route>
    </Route>
    <Route path="/inloggen" component={Login} onEnter={resetForm} />
    <Route path="/registreren" component={Register} onEnter={resetForm} />
    <Route
      path="/activeren/:id/:hash"
      component={Activate}
      onEnter={resetForm}
    />
    <Route
      path="/wachtwoord-vergeten"
      component={ResetPassword}
      onEnter={resetForm}
    />
    <Route
      path="/wachtwoord-wijzigen/:id/:hash"
      component={ChangePassword}
      onEnter={resetForm}
    />
    <Route path="/tab/:alias" component={Tab} onEnter={resetForm} />
  </Route>
);

export default routes;
