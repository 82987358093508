import empty from "empty";
import { getJSON, postJSON } from "./utils";

const reset = () => {
  sessionStorage.removeItem("variant");
  sessionStorage.removeItem("auth");
};

const check = async (headers = empty.array) =>
  await getJSON("/api/afval/accounts/login", empty.array, headers);

const login = async (username, password) =>
  await check([
    {
      key: "Authorization",
      value: `Basic ${btoa(`${username}:${password}`)}`
    }
  ]);

const register = async account =>
  postJSON("/api/afval/accounts/register", account);

const saveAccount = async account =>
  postJSON(`/api/afval/accounts/${account.id}`, account);

const deleteAccount = async id => postJSON(`/api/afval/accounts/${id}/delete`);

const resetPassword = async account =>
  postJSON("/api/afval/accounts/resetpassword", account);

const changePassword = async account =>
  postJSON("/api/afval/accounts/changepassword", account);

const activate = async (id, hash) =>
  postJSON(`/api/afval/accounts/activate/${id}/${hash}`);

export default {
  activate,
  changePassword,
  check,
  deleteAccount,
  login,
  register,
  reset,
  resetPassword,
  saveAccount
};
