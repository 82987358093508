import empty from "empty";
import { accounts, data, results } from "../api";
import { asyncActionCreator, composeAction } from "./utils";

export const resetFormAction = () => {
  return {
    type: "FORM_RESET"
  };
};

export const resetLoginAction = () => {
  accounts.reset();
  return {
    type: "LOGIN_RESET",
    payload: empty.object
  };
};

const loginCallbacks = {
  callbackAfter: (payload = empty.object, dispatch) => {
    if (payload && !payload.id && !payload.active) {
      // inloggen is niet goed gegaan...
      dispatch(resetLoginAction());
    }
  },
  callbackError: dispatch => dispatch(resetLoginAction())
};

export const loginAction = ({ username, password }) =>
  asyncActionCreator(
    composeAction(
      {
        group: "LOGIN_USER",
        fire: (username, password) => accounts.login(username, password),
        args: [username, password],
        cachekey: "user",
        container: "session"
      },
      loginCallbacks
    )
  );

export const checkLoginAction = () =>
  asyncActionCreator(
    composeAction(
      {
        group: "LOGIN_USER_CHECK",
        fire: () => accounts.check(),
        cachekey: "user",
        container: "session"
      },
      loginCallbacks
    )
  );

export const logoutAction = () => {
  accounts.reset();
  return {
    type: "LOGOUT_USER"
  };
};

export const resetPasswordAction = user =>
  asyncActionCreator({
    group: "RESET_PASSWORD",
    fire: user => accounts.resetPassword(user),
    args: [user]
  });

export const changePasswordAction = user =>
  asyncActionCreator({
    group: "CHANGE_PASSWORD",
    fire: user => accounts.changePassword(user),
    args: [user]
  });

export const registerAction = user =>
  asyncActionCreator({
    group: "REGISTER_USER",
    fire: user => accounts.register(user),
    args: [user]
  });

export const activateAction = (id, hash) =>
  asyncActionCreator({
    group: "ACTIVATE_USER",
    fire: (id, hash) => accounts.activate(id, hash),
    args: [id, hash]
  });

export const referenceListsAction = () =>
  asyncActionCreator({
    group: "REFERENCELISTS",
    fire: () => data.referenceLists(),
    cachekey: "referenceLists"
  });

export const formResultsAction = id =>
  asyncActionCreator({
    group: "FORM_RESULTS",
    fire: id => results.get(id),
    args: [id]
  });

const formResultCallbacks = {
  callbackAfter: (_, dispatch) => {
    // refresh after delete
    dispatch(formResultsAction());
  }
};

export const deleteFormResultAction = id =>
  asyncActionCreator(
    composeAction(
      {
        group: "DELETE_FORM_RESULT",
        fire: id => results.deleteResult(id),
        args: [id]
      },
      formResultCallbacks
    )
  );

export const formCatalogAction = () =>
  asyncActionCreator({
    group: "FORMCATALOG",
    fire: () => data.formCatalog()
  });

export const allAccountsAction = () =>
  asyncActionCreator({
    group: "GET_ACCOUNTS",
    fire: () => data.allAccounts()
  });

export const filterAccountsAction = keyword => ({
  type: "SET_KEYWORD",
  payload: {
    keyword
  }
});

export const saveAccountAction = user =>
  asyncActionCreator({
    group: "SAVE_USER",
    fire: user => accounts.saveAccount(user),
    args: [user]
  });

export const deleteAccountAction = id =>
  asyncActionCreator({
    group: "DELETE_USER",
    fire: id => accounts.deleteAccount(id),
    args: [id]
  });

export const statsAction = year =>
  asyncActionCreator({
    group: "GET_STATS",
    fire: year => results.stats(year),
    args: [year],
    cachekey: "stats"
  });
