import compose from "recompose/compose";
import pure from "recompose/pure";
import Register from "../components/register.jsx";
import { connect } from "react-redux";
import { registerAction } from "../actions";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = ({ form: { result } }) => ({
  result
});

/**
 * Map dispatch to props
 */
const mapDispatch = dispatch => ({
  submit: user => dispatch(registerAction(user))
});

/**
 * Compose Higher-order component
 */
export default compose(
  pure,
  connect(mapState, mapDispatch),
  withRefLists("registreren:")
)(Register);
