import classNames from "classnames";
import compose from "recompose/compose";
import debounce from "lodash/debounce";
import empty from "empty";
import every from "lodash/fp/every";
import omit from "lodash/omit";
import onlyUpdateForKeys from "recompose/onlyUpdateForKeys";
import PropTypes from "prop-types";
import pure from "recompose/pure";
import React, { Children, cloneElement } from "react";
import setDisplayName from "recompose/setDisplayName";
import setPropTypes from "recompose/setPropTypes";
import withHandlers from "recompose/withHandlers";
import withProps from "recompose/withProps";
import withState from "recompose/withState";

/**
 * Form component
 * @return {React Element}
 */
const Form = ({
  children,
  form,
  onBlur,
  onChange,
  onSubmit,
  result = empty.object,
  texts = empty.object,
  title
}) => {
  if (result.success) {
    return (
      <div>
        <p>{texts.success}</p>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit} className="formControl">
      {result.success === false && (
        <div className="formErrorText">
          <p>{texts.error}</p>
        </div>
      )}
      {children && (
        <div className="hidden">
          {Children.map(children, child => {
            const { type } = child.props;
            if (type === "hidden") {
              return cloneElement(child, { onBlur, onChange, title: null });
            }

            return null;
          })}
        </div>
      )}
      {children && (
        <fieldset>
          {title && <legend>{title}</legend>}
          {Children.map(children, child => {
            const {
              title,
              id,
              name,
              type,
              required,
              defaultValue,
              value
            } = child.props;
            if (type === "hidden") {
              return null;
            }

            const inputProps = {
              onBlur,
              onChange,
              title: undefined,
              placeholder: title,
              checked: type === "checkbox" ? defaultValue : undefined,
              value: type === "checkbox" ? undefined : value
            };

            return (
              <div
                key={id}
                className={classNames("formField", {
                  typeTextField: type !== "checkbox",
                  typeSelectionField: type === "checkbox",
                  hidelabel: type === "checkbox",
                  validationNone:
                    !form.errors ||
                    (form.errors && form.errors[name] === undefined),
                  validationError: form.errors && form.errors[name] === true,
                  validationCompleted:
                    form.errors && form.errors[name] === false
                })}
              >
                <label htmlFor={id}>
                  {title}
                  {required && <span className="requiredStar">*</span>}
                </label>
                {type !== "checkbox" && (
                  <div className="input">{cloneElement(child, inputProps)}</div>
                )}
                {type === "checkbox" && (
                  <ul className="choices horizontal">
                    <li className="geenliststyle">
                      <label>
                        {cloneElement(child, inputProps)}
                        <span className="checkbox-desc">{title}</span>
                      </label>
                    </li>
                  </ul>
                )}
                {form.errors &&
                  form.errors[name] === true &&
                  texts.errors &&
                  texts.errors[name] && (
                    <div className="icon-error">{texts.errors[name]}</div>
                  )}
              </div>
            );
          })}
        </fieldset>
      )}
      <div className="buttons">
        <input
          type="submit"
          disabled={!form.valid}
          className={classNames("default-button", "type-submit", {
            disabled: !form.valid
          })}
          value={texts.button}
        />
      </div>
    </form>
  );
};

const isEmpty = value => value === undefined || value === null || value === "";

const getError = ({ required, minLength, type, value }) =>
  isEmpty(value)
    ? required
    : (minLength > 0 && !(value.length >= minLength)) ||
      (type === "email" && !/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(value));

const noErrors = errors =>
  every(error => error === false)(Object.keys(errors).map(key => errors[key]));

const inputProps = element => {
  const { id, name, value, required, type, checked } = element;
  return {
    id,
    name,
    value,
    required,
    type,
    checked,
    minLength: parseInt(element.getAttribute("minlength"), 10) || 0
  };
};

const initialFormState = ({ children }) => {
  const childProps = Children.map(
    children,
    ({
      props: {
        name,
        required,
        minLength,
        type,
        value,
        defaultValue,
        defaultChecked
      }
    }) => ({
      name,
      required,
      minLength,
      type,
      value: type === "checkbox" ? defaultChecked : value || defaultValue
    })
  );

  const values = childProps.reduce(
    (a, { name, value }) => ({ ...a, [name]: isEmpty(value) ? "" : value }),
    empty.object
  );
  const errors = childProps.reduce(
    (a, child) => ({ ...a, [child.name]: getError(child) ? null : false }),
    empty.object
  );

  return {
    ...values,
    errors,
    valid: noErrors(errors)
  };
};

const updateFormState = (
  { errors, valid, ...values }, // eslint-disable-line no-unused-vars
  child = empty.object
) => {
  const nextErrors = { ...errors, [child.id]: getError(child) };
  return {
    ...values,
    [child.name]:
      child.type === "checkbox"
        ? child.checked
        : isEmpty(child.value)
        ? ""
        : child.value,
    errors: nextErrors,
    valid: noErrors(nextErrors)
  };
};

/**
 * Compose Higher-order component
 */
export default compose(
  setDisplayName("Form"),
  pure,
  setPropTypes({
    title: PropTypes.string,
    submit: PropTypes.func.isRequired,
    texts: PropTypes.shape({
      success: PropTypes.string.isRequired,
      error: PropTypes.string.isRequired,
      status: PropTypes.object,
      errors: PropTypes.object,
      button: PropTypes.string.isRequired
    }).isRequired
  }),
  withProps(({ texts: { error, status, ...otherTexts }, result }) => ({
    texts: {
      error: (result && status && status[result.status]) || error,
      ...otherTexts
    }
  })),
  withState("form", "update", initialFormState),
  withProps(({ form, update }) => {
    const debounced = debounce(update, 1000);
    const wrapper = nextForm => {
      debounced.cancel();
      if (nextForm.valid) {
        update(nextForm);
      } else {
        debounced(nextForm);
        if (form.valid) {
          update({ ...form, valid: false });
        }
      }
    };
    wrapper.cancel = debounced.cancel;
    wrapper.flush = debounced.flush;
    return { update: wrapper };
  }),
  onlyUpdateForKeys(["children", "form", "result"]),
  withHandlers({
    onBlur: ({ update }) => () => update.flush(),
    onChange: ({ form, update }) => event =>
      update(updateFormState(form, inputProps(event.target))),
    onSubmit: ({ form, submit }) => event => {
      event.preventDefault();
      if (form.valid) {
        submit(omit(form, "errors", "valid"));
      }
    }
  })
)(Form);
