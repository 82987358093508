import compose from "recompose/compose";
import defaultProps from "recompose/defaultProps";
import empty from "empty";
import lifecycle from "recompose/lifecycle";
import pure from "recompose/pure";
import setDisplayName from "recompose/setDisplayName";
import { connect } from "react-redux";
import { hashHistory } from "react-router";

/**
 * Slechts een doorgeefluik...
 */
const Authenticated = props => props.children;

/**
 * Map state to props
 */
const mapState = ({ session: { user } = empty.object }) => ({
  user: user,
  isAuthenticated:
    user !== null &&
    user.id !== null &&
    user !== undefined &&
    user.id !== undefined
});

/**
 * Authenticated higher-order component
 */
export default compose(
  setDisplayName("Authenticated"),
  pure,
  connect(mapState),
  defaultProps({
    user: null,
    isAuthenticated: false
  }),
  lifecycle({
    componentWillMount: function() {
      if (!this.props.isAuthenticated) {
        // niet ingelogd
        const next = this.props.location.pathname;
        hashHistory.push(`/inloggen?next=${next}`);
      }
    },
    componentWillReceiveProps: function(nextProps) {
      if (!nextProps.isAuthenticated) {
        // niet ingelogd
        const next = nextProps.location.pathname;
        hashHistory.push(`/inloggen?next=${next}`);
      }
    }
  })
)(Authenticated);
