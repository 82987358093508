import empty from "empty";
import { combineReducers } from "redux";
import { Viewport } from "../actions/viewport";

const viewportReducer = ({ viewport = Viewport.size } = empty.object, action) =>
  action.type === "RESIZE_MEASURE" ? action.viewport : viewport;

const filterReducer = (state, { type, payload }) =>
  type === "SET_KEYWORD" ? { keyword: payload.keyword } : empty.object;

/**
 * UI-reducer; this reducer creates all data needed for the app
 * @return {Redux reducer}
 */
const uiReducers = combineReducers({
  filter: filterReducer,
  viewport: viewportReducer
});

export default uiReducers;
