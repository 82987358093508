import empty from "empty";
import Form from "./form.jsx";
import React from "react";
import { Link } from "react-router";

/**
 * Account React component
 * @return {React Element}
 */
const Account = ({
  children,
  submit,
  result = empty.object,
  refLists,
  account = empty.object
}) =>
  children ? (
    children
  ) : (
    <div>
      <h1>{refLists.getText("Bewerken")}</h1>
      {!result.success && refLists.getDiv("Inleiding account-bewerken")}
      <p>
        <Link to="/admin">
          {refLists.getText("Terug naar het overzicht van gebruikers")}
        </Link>
      </p>
      {account && account.id && (
        <Form
          submit={submit}
          result={result}
          texts={{
            success: refLists.getText("Uw wijzigingen zijn opgeslagen."),
            error: refLists.getText(
              "Er is een fout opgetreden, let er op dat een e-mailadres uniek moet zijn."
            ),
            errors: {
              name: refLists.getText("Dit is een verplicht veld"),
              email: refLists.getText(
                "Dit verplichte veld dient een e-mailadres te bevatten"
              ),
              password: refLists.getText(
                "Het wachtwoord veld moet minimaal 8 karakters langs zijn"
              )
            },
            button: refLists.getText("Opslaan")
          }}
        >
          <input
            name="name"
            id="name"
            title={refLists.getText("Naam")}
            type="text"
            defaultValue={account.name}
            required={true}
          />
          <input
            name="email"
            id="email"
            title={refLists.getText("E-mailadres")}
            type="email"
            defaultValue={account.email}
            required={true}
          />
          <input
            name="active"
            id="active"
            title={refLists.getText("Actieve gebruiker")}
            type="checkbox"
            defaultChecked={account.active}
          />
          <input
            name="isAdmin"
            id="isAdmin"
            title={refLists.getText("Beheerder")}
            type="checkbox"
            defaultChecked={account.isAdmin}
          />
        </Form>
      )}
    </div>
  );

export default Account;
