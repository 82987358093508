import empty from "empty";
import { connect } from "react-redux";
import compose from "recompose/compose";
import mapProps from "recompose/mapProps";
import pure from "recompose/pure";
import Tab from "../components/tab";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = ({
  data: {
    referenceLists: { tabs = empty.object } = empty.object
  } = empty.object
}) => ({
  tabs
});

/**
 * Compose Higher-order component
 */
export default compose(
  pure,
  withRefLists("tab:"),
  connect(mapState),
  mapProps(
    ({
      params,
      params: { alias } = empty.object,
      tabs = empty.object,
      ...rest
    }) => ({ params, page: { title: alias, ...tabs[alias] }, ...rest })
  )
)(Tab);
