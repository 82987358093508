import empty from "empty";
import { combineReducers } from "redux";

/**
 * Result-reducer; this reducers creates data needed for a form
 * @return {Redux reducer}
 */
const resultReducer = (state = empty.object, { type, payload }) => {
  switch (type) {
    case "FORM_RESET":
      return empty.object;
    case "ACTIVATE_USER_ERROR":
    case "CHANGE_PASSWORD_ERROR":
    case "LOGIN_USER_ERROR":
    case "REGISTER_USER_ERROR":
    case "RESET_PASSWORD_ERROR":
    case "SAVE_USER_ERROR":
      return {
        success: false,
        status: payload.error ? payload.error.status : undefined,
        error: true,
        loading: payload.loading
      };
    case "ACTIVATE_USER_INIT":
    case "CHANGE_PASSWORD_INIT":
    case "REGISTER_USER_INIT":
    case "RESET_PASSWORD_INIT":
    case "SAVE_USER_INIT":
      return { loading: payload.loading };
    case "ACTIVATE_USER_EXIT":
    case "CHANGE_PASSWORD_EXIT":
    case "REGISTER_USER_EXIT":
    case "RESET_PASSWORD_EXIT":
    case "SAVE_USER_EXIT":
      return { ...state, loading: payload.loading };
    case "ACTIVATE_USER_SUCCESS":
    case "CHANGE_PASSWORD_SUCCESS":
      return {
        success: payload.data && payload.data.active,
        loading: payload.loading
      };
    case "REGISTER_USER_SUCCESS":
    case "RESET_PASSWORD_SUCCESS":
      if (payload.data && payload.data.success) {
        return {
          success: payload.data.success,
          loading: payload.loading
        };
      }

      return state;
    case "SAVE_USER_SUCCESS":
      if (payload.data && payload.data.id) {
        return {
          success: true,
          loading: payload.loading
        };
      }

      return state;
    default:
      return state;
  }
};

/**
 * Form-reducer; this reducer creates all form-data needed for the app
 * @return {Redux reducer}
 */
const formReducers = combineReducers({
  result: resultReducer
});

export default formReducers;
