import empty from "empty";

export const composeAction = (...actions) => {
  const composeCallback = (f1, f2) => {
    const fun1 = typeof f1 === "function";
    const fun2 = typeof f2 === "function";
    return fun1 && fun2
      ? (...args) => Promise.all([f1(...args), f2(...args)])
      : fun1
      ? f1
      : fun2
      ? f2
      : undefined;
  };

  const composeProps = (a1, a2) => ({
    ...a1,
    ...a2,
    callbackBefore: composeCallback(a1.callbackBefore, a2.callbackBefore),
    callbackAfter: composeCallback(a1.callbackAfter, a2.callbackAfter),
    callbackError: composeCallback(a1.callbackError, a2.callbackError)
  });

  return actions.reduce(composeProps, empty.object);
};

export const asyncActionCreator = ({
  group,
  fire,
  args,
  key,
  cachekey,
  container = "data",
  callbackBefore,
  callbackAfter,
  callbackError
}) => {
  const action = (type, payload) => ({
    type: `${group}_${type}`,
    payload: payload || null
  });

  return async (dispatch, getState) => {
    if (cachekey) {
      const state = getState();
      if (state[container]) {
        const cache = state[container][cachekey];
        const cached = cache && cache[args];

        if (cached) {
          dispatch(
            action(
              "SUCCESS",
              key
                ? {
                    id: cached[key],
                    data: cached
                  }
                : {
                    loading: "success",
                    data: cached
                  }
            )
          );

          return;
        }
      }
    }

    dispatch(action("INIT", { loading: "init" }));
    return await (async () => {
      let success = false;
      if (callbackBefore) {
        await callbackBefore(dispatch, args);
      }

      let payload;
      try {
        payload = await fire.apply(this, args);
        success = true;

        if (callbackAfter) {
          await callbackAfter(payload, dispatch, args);
        }
      } catch (e) {
        if (callbackError) {
          await callbackError(dispatch, args);
        }

        dispatch(action("ERROR", { error: e, loading: "error" }));
      }

      if (success) {
        dispatch(
          action("SUCCESS", {
            loading: "success",
            id:
              key && payload && key in payload
                ? payload[key]
                : key === "id"
                ? args[0]
                : undefined,
            data: payload
          })
        );
      }

      dispatch(action("EXIT", { loading: "exit" }));
    })();
  };
};
