import Account from "../components/account.jsx";
import assign from "object-assign";
import compose from "recompose/compose";
import empty from "empty";
import pure from "recompose/pure";
import withProps from "recompose/withProps";
import { connect } from "react-redux";
import { saveAccountAction } from "../actions";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = (
  {
    form: { result = empty.object },
    data: { accounts: { list = empty.array } = empty.object }
  },
  { params: { id = 0 } = empty.object }
) => ({
  result,
  account: list[id]
});

/**
 * Map dispatch to props
 */
const mapDispatch = dispatch => ({
  dispatchSubmit: user => {
    dispatch(saveAccountAction(user));
  }
});

/**
 * Compose Higher-order component
 */
export default compose(
  pure,
  connect(mapState, mapDispatch),
  withProps(({ account, dispatchSubmit }) => ({
    submit: user => dispatchSubmit(assign({}, account, user))
  })),
  withRefLists("account:")
)(Account);
