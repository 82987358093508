import empty from "empty";
import Form from "./form.jsx";
import React from "react";

/**
 * ResetPassword React component
 * @return {React Element}
 */
const ResetPassword = ({
  submit,
  result = empty.object,
  refLists = empty.object
}) => (
  <div>
    <h1>{refLists.getText("Wachtwoord vergeten?")}</h1>
    <Form
      submit={submit}
      result={result}
      texts={{
        success: refLists.getText("Een mail met een link is verzonden."),
        error: refLists.getText("E-mailadres is niet gevonden."),
        errors: {
          email: refLists.getText(
            "Dit verplichte veld dient een e-mailadres te bevatten"
          )
        },
        button: refLists.getText("Versturen")
      }}
    >
      <input
        name="email"
        id="email"
        title={refLists.getText("E-mailadres")}
        type="email"
        required={true}
      />
    </Form>
  </div>
);

export default ResetPassword;
