import { getJSON } from "./utils";

const referenceLists = async () => await getJSON("/api/afval/reflist");

const formCatalog = async () => await getJSON("/api/afval/catalog");

const allAccounts = async () => await getJSON("/api/afval/accounts");

export default {
  allAccounts,
  formCatalog,
  referenceLists
};
