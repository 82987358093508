import empty from "empty";
import Form from "./form.jsx";
import React from "react";

/**
 * ChangePassword React component
 * @return {React Element}
 */
const ChangePassword = ({
  submit,
  result = empty.object,
  params,
  refLists
}) => (
  <div>
    <h1>{refLists.getText("Wachtwoord wijzigen")}</h1>
    {refLists.getDiv("Inleiding wachtwoord wijzigen")}
    <Form
      submit={submit}
      texts={{
        success: refLists.getText("Uw wachtwoord is gewijzigd."),
        error: refLists.getText(
          "Het wijzigen van uw wachtwoord is niet gelukt."
        ),
        errors: {
          password: refLists.getText(
            "Het wachtwoord veld moet minimaal 8 karakters langs zijn"
          )
        },
        button: refLists.getText("Wijzigen")
      }}
      result={result}
    >
      <input name="id" id="id" title="ID" type="hidden" value={params.id} />
      <input
        name="hash"
        id="hash"
        title="Hash"
        type="hidden"
        value={params.hash}
      />
      <input
        name="newPassword"
        id="newPassword"
        title={refLists.getText("Nieuw Wachtwoord")}
        type="password"
        minLength={8}
        required={true}
      />
    </Form>
  </div>
);

export default ChangePassword;
