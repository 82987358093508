import Admin from "../components/admin.jsx";
import compose from "recompose/compose";
import empty from "empty";
import pure from "recompose/pure";
import withHandlers from "recompose/withHandlers";
import zipObject from "lodash/zipObject";
import { connect } from "react-redux";
import {
  filterAccountsAction,
  deleteAccountAction,
  formResultsAction
} from "../actions";
import { formatUtcDate, getStatus } from "../utils";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = ({
  ui: {
    filter: { keyword = "" }
  },
  data: {
    accounts: { list = empty.array },
    results = empty.object,
    forms = empty.object,
    stats = empty.object
  },
  session: { user }
}) => ({
  accounts: {
    list,
    filtered: Object.keys(list).filter(id => {
      if (keyword && keyword !== "") {
        const account = list[id];
        const regex = new RegExp(keyword, "i");
        return `${account.name} ${account.email}`.match(regex);
      }

      return true;
    })
  },
  results: {
    ...zipObject(
      Object.keys(results),
      Object.keys(results).map(id => {
        if (id.indexOf("afval:") > -1) {
          return results[id].list.map(r => {
            const type =
              forms.list && forms.list[r.formId]
                ? forms.list[r.formId].label
                : `Formulier ${r.formId}`;

            const name = `${type} ${formatUtcDate(r.created)}`;
            const status = getStatus(r);

            return {
              name,
              status,
              result: "Onbekend" /* TODO: Deze waarde bepalen... */,
              ...r
            };
          });
        } else {
          return null;
        }
      })
    )
  },
  user,
  stats: stats.data
});

/**
 * Map dispatch to props
 */
const mapDispatch = dispatch => ({
  getResults: id => dispatch(formResultsAction(id)),
  deleteAccount: id => dispatch(deleteAccountAction(id)),
  filter: keyword => dispatch(filterAccountsAction(keyword))
});

/**
 * Compose Higher-order component
 */
export default compose(
  pure,
  connect(mapState, mapDispatch),
  withRefLists("activate:"),
  withHandlers({
    setFilter: props => event => {
      event.preventDefault();
      const keyword = event.target ? event.target.value : "";
      props.filter(keyword);
    }
  })
)(Admin);
