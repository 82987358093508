import empty from "empty";
import Overview from "./overview.jsx";
import React from "react";
import { Link } from "react-router";

const AccountResults = ({
  account,
  refLists,
  viewport,
  results = empty.array
}) =>
  !account ? null : (
    <div>
      <h1>
        {refLists.getText("Beoordelingen van")} {account.name}
      </h1>
      {refLists.getDiv("Inleiding beoordelingenscherm")}
      <p>
        <Link to="/admin">
          {refLists.getText("Terug naar het overzicht van gebruikers")}
        </Link>
      </p>
      {results.length > 0 && (
        <Overview
          viewport={viewport}
          results={results}
          refLists={refLists}
          forAdmin={true}
          buttonsEnabled={{
            pdf: false,
            edit: false,
            delete: false
          }}
        />
      )}
    </div>
  );

export default AccountResults;
