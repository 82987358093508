import empty from "empty";
import keys from "lodash/fp/keys";
import { connect } from "react-redux";
import compose from "recompose/compose";
import lifecycle from "recompose/lifecycle";
import { logoutAction } from "../actions";
import { resize } from "../actions/viewport";
import App from "../components/app.jsx";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = ({
  session: { user } = empty.object,
  data: {
    referenceLists: { tabs = empty.object } = empty.object
  } = empty.object
}) => ({ user, tabs: keys(tabs) });

/**
 * Map state to props
 */
const mapDispatch = dispatch => ({
  logout: () => dispatch(logoutAction()),
  resize: () => dispatch(resize())
});

/**
 * Compose Higher-order component
 */
export default compose(
  connect(mapState, mapDispatch),
  withRefLists("main:"),
  lifecycle({
    componentWillMount: function() {
      window.addEventListener("resize", this.props.resize);
    },
    componentWillUnmount: function() {
      window.removeEventListener("resize", this.props.resize);
    }
  })
)(App);
