import compose from "recompose/compose";
import empty from "empty";
import Home from "../components/home.jsx";
import { connect } from "react-redux";
import { deleteFormResultAction } from "../actions";
import { formatUtcDate, getStatus } from "../utils";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = ({
  data: { results = empty.object, forms = empty.object },
  session: {
    user: { id }
  },
  ui: { viewport = empty.object }
}) => {
  const list = results[`afval:${id}`]
    ? results[`afval:${id}`].list
    : empty.array;
  return {
    forms: forms.list ? forms.list : empty.object,
    results: list.map(r => {
      const type =
        forms.list && forms.list[r.formId]
          ? forms.list[r.formId].label
          : `Formulier ${r.formId}`;

      const name = `${type} ${formatUtcDate(r.created)}`;
      const status = getStatus(r);

      return {
        name,
        status,
        result: "Onbekend" /* TODO: Deze waarde bepalen... */,
        ...r
      };
    }),
    viewport
  };
};

const mapDispatch = dispatch => ({
  deleteResult: id => dispatch(deleteFormResultAction(id))
});

/**
 * Compose Higher-order component
 */
export default compose(
  connect(mapState, mapDispatch),
  withRefLists("start:")
)(Home);
