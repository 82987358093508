import empty from "empty";
import Form from "./form.jsx";
import React from "react";
import { Link } from "react-router";

/**
 * Login React component
 * @return {React Element}
 */
const Login = ({ checked, submit, result = empty.object, refLists }) => {
  if (!checked) {
    return null;
  }

  return (
    <div>
      <h1>{refLists.getText("Inloggen")}</h1>
      {refLists.getDiv("Inleiding inloggen")}
      <Form
        submit={submit}
        texts={{
          success: refLists.getText("U bent ingelogd."),
          error: refLists.getText("Gebruikersnaam of wachtwoord zijn onjuist."),
          errors: {
            username: refLists.getText(
              "Dit verplichte veld dient een e-mailadres te bevatten"
            ),
            password: refLists.getText(
              "Het wachtwoord veld moet minimaal 8 karakters langs zijn"
            )
          },
          button: refLists.getText("Inloggen")
        }}
        result={result}
      >
        <input
          name="username"
          id="username"
          title={refLists.getText("E-mailadres")}
          type="email"
          required={true}
        />
        <input
          name="password"
          id="password"
          title={refLists.getText("Wachtwoord")}
          type="password"
          minLength={8}
          required={true}
        />
      </Form>
      <div>
        <p>
          <Link to="/wachtwoord-vergeten">
            {refLists.getText("Wachtwoord vergeten?")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
