import assign from "object-assign";
import empty from "empty";
import keyBy from "lodash/keyBy";
import omitBy from "lodash/omitBy";
import { applyLoading } from "./utils";
import { combineReducers } from "redux";

/**
 * ReferenceLists-reducer; this reducers creates data needed for reference lists
 * @return {Redux reducer}
 */
const referenceListsReducer = (state = empty.object, { type, payload }) => {
  switch (type) {
    case "REFERENCELISTS_ERROR":
    case "REFERENCELISTS_EXIT":
    case "REFERENCELISTS_INIT":
      return applyLoading(state, payload);
    case "REFERENCELISTS_SUCCESS":
      return { ...payload.data, loading: payload.loading };
    default:
      return state;
  }
};

/**
 * FormResults-reducer; this reducers creates data needed for a session
 * @return {Redux reducer}
 */
const formResultsReducer = (state = empty.object, { type, payload }) => {
  switch (type) {
    case "FORM_RESULTS_ERROR":
    case "FORM_RESULTS_EXIT":
    case "FORM_RESULTS_INIT":
      return applyLoading(state, payload);
    case "FORM_RESULTS_SUCCESS": {
      const resultObj = payload.data;
      return {
        ...state,
        [resultObj.userId]: resultObj,
        loading: payload.loading
      };
    }
    default:
      return state;
  }
};

/**
 * Stats-reducer; this reducers creates data needed for a session
 * @return {Redux reducer}
 */
const statsReducer = (state = empty.object, { type, payload }) => {
  switch (type) {
    case "GET_STATS_ERROR":
    case "GET_STATS_EXIT":
    case "GET_STATS_INIT":
      return applyLoading(state, payload);
    case "GET_STATS_SUCCESS":
      return {
        ...state,
        [payload.data.year]: payload.data,
        loading: payload.loading
      };
    default:
      return state;
  }
};

/**
 * FormCatalog-reducer; this reducers creates data needed for a session
 * @return {Redux reducer}
 */
const formCatalogReducer = (state = empty.object, { type, payload }) => {
  switch (type) {
    case "FORMCATALOG_ERROR":
    case "FORMCATALOG_EXIT":
    case "FORMCATALOG_INIT":
      return applyLoading(state, payload);
    case "FORMCATALOG_SUCCESS": {
      const list = payload.data.list
        ? keyBy(payload.data.list, "id")
        : empty.object;
      return { list, loading: payload.loading };
    }
    default:
      return state;
  }
};

/**
 * Accounts-reducer; this reducers creates data needed for a session
 * @return {Redux reducer}
 */
const accountsReducer = (state = empty.object, { type, payload }) => {
  switch (type) {
    case "GET_ACCOUNTS_ERROR":
    case "GET_ACCOUNTS_EXIT":
    case "GET_ACCOUNTS_INIT":
      return applyLoading(state, payload);
    case "GET_ACCOUNTS_SUCCESS": {
      const list = payload.data.list
        ? keyBy(payload.data.list, "id")
        : empty.object;
      return { list, loading: payload.loading };
    }
    case "DELETE_USER_SUCCESS": {
      const list = omitBy(
        state.list,
        account => account.id === payload.data.id
      );
      return { list, loading: payload.loading };
    }
    case "SAVE_USER_SUCCESS": {
      const user = payload.data;
      const current = state.list[user.id];
      user.results = current.results;

      return {
        list: {
          ...state.list,
          [user.id]: assign({}, current, user)
        },
        loading: payload.loading
      };
    }
    default:
      return state;
  }
};

/**
 * Data-reducer; this reducer creates all data needed for the app
 * @return {Redux reducer}
 */
const dataReducers = combineReducers({
  accounts: accountsReducer,
  forms: formCatalogReducer,
  referenceLists: referenceListsReducer,
  results: formResultsReducer,
  stats: statsReducer
});

export default dataReducers;
