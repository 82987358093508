import moment from "moment";

export const formatUtcDate = (utcDateString, outputFormat = "lll") =>
  moment.utc(utcDateString).format(outputFormat);

export const getStatus = formResult => {
  if (formResult.ready) {
    return "Afgesloten";
  } else if (formResult.complete) {
    return "Afgerond";
  } else {
    return "In bewerking";
  }
};

export const range = (start, end) =>
  Array.from(
    {
      length: end - start
    },
    (v, k) => k + start
  );

export const preventDefault = e => e.preventDefault();
