const config = {
  development: {
    apiPrefix: ""
  },
  production: {
    apiPrefix: ""
  }
};

export default config[process.env.NODE_ENV];
