import empty from "empty";
import OpenFormButton from "./openFormButton.jsx";
import Overview from "./overview.jsx";
import React from "react";

/**
 * Home React component
 * @return {React Element}
 */
const Home = ({
  viewport,
  forms = empty.object,
  results = empty.array,
  deleteResult,
  refLists
}) => {
  return (
    <div>
      <h1>{refLists.getText("Welkom")}</h1>
      {refLists.getDiv("Inleiding startscherm")}
      <Overview
        viewport={viewport}
        results={results}
        refLists={refLists}
        deleteResult={deleteResult}
        forms={forms}
      />
      {Object.keys(forms).length > 0 && (
        <div className="form-buttons">
          {Object.keys(forms).map(id => (
            <OpenFormButton key={id} form={forms[id]} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;
