import Activate from "../components/activate.jsx";
import compose from "recompose/compose";
import lifecycle from "recompose/lifecycle";
import pure from "recompose/pure";
import { activateAction } from "../actions";
import { connect } from "react-redux";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = ({ form: { result }, session: { user } }) => ({
  result,
  user
});

/**
 * Map dispatch to props
 */
const mapDispatch = (dispatch, { params: { id, hash } }) => ({
  activate: () => dispatch(activateAction(id, hash))
});

/**
 * Compose Higher-order component
 */
export default compose(
  pure,
  connect(mapState, mapDispatch),
  withRefLists("activate:"),
  lifecycle({
    componentWillMount: function() {
      if (!this.props.user || (this.props.user && !this.props.user.active)) {
        this.props.activate();
      }
    }
  })
)(Activate);
