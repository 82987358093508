import empty from "empty";
import React from "react";
import SafeInnerHtml from "react-safeinnerhtml";

/**
 * Tab React component
 * @return {React Element}
 */
const Tab = ({
  page: { title, html } = empty.object,
  refLists = empty.object
}) => (
  <div>
    {title && <h1>{refLists.getText(title)}</h1>}
    <div>{html && <SafeInnerHtml key={title}>{html}</SafeInnerHtml>}</div>
  </div>
);

export default Tab;
