import compose from "recompose/compose";
import empty from "empty";
import mapProps from "recompose/mapProps";
import React from "react";
import { connect } from "react-redux";

/** Converts HTML to text */
export const html2text = html => {
  if (typeof html !== "string" || html === "") {
    return undefined;
  }

  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent;
};

/** Converts text to HTML */
export const text2html = text => {
  if (typeof text !== "string" || text === "") {
    return undefined;
  }

  const div = document.createElement("div");
  div.textContent = text;
  return div.innerHTML;
};

/** Creates referenceLists object */
export const referenceLists = (
  reference = empty.object,
  extraLists = empty.array,
  ...extraPrefixes
) => {
  const lists = [...extraLists, "afval"];
  const prefixes = [...extraPrefixes, ""];
  const findObject = alias => {
    for (const listKey of lists) {
      const list = reference[listKey];
      if (!list) {
        continue;
      }

      for (const prefix of prefixes) {
        const item = list[`${prefix}${alias}`];
        if (item) {
          return item;
        }
      }
    }

    return empty.object;
  };

  class ReferenceListsLookup {
    /** Initializes the lookup */
    constructor() {
      this.reference = reference;
      this.lists = lists;
    }
    /** Gets text or undefined */
    getTextOrNull(alias) {
      const { html, text } = findObject(alias);
      return html2text(html) || text;
    }

    /** Gets text */
    getText(alias) {
      return this.getTextOrNull(alias) || alias;
    }

    /** Gets HTML or undefined */
    getHtmlOrNull(alias) {
      const { html, text } = findObject(alias);
      return html || text2html(text);
    }

    /** Gets HTML */
    getHtml(alias) {
      return this.getHtmlOrNull(alias) || text2html(alias);
    }

    /** Gets div */
    getDiv(alias, optional = false) {
      const __html = optional ? this.getHtmlOrNull(alias) : this.getHtml(alias);
      return __html ? <div dangerouslySetInnerHTML={{ __html }} /> : null;
    }
  }

  return new ReferenceListsLookup();
};

const connectRefLists = connect(
  ({ data: { referenceLists = empty.object } }) => ({
    referenceListsData: referenceLists
  })
);

const mapRefListsProps = extraPrefixes =>
  mapProps(
    ({
      referenceListsData = empty.object,
      extraLists = empty.array,
      ...otherProps
    }) => ({
      refLists: referenceLists(
        referenceListsData,
        extraLists,
        ...extraPrefixes
      ),
      extraLists,
      ...otherProps
    })
  );

/** Adds refLists property */
export const withRefLists = (...extraPrefixes) =>
  compose(connectRefLists, mapRefListsProps(extraPrefixes));
