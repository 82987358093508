import ChangePassword from "../components/changePassword.jsx";
import compose from "recompose/compose";
import pure from "recompose/pure";
import { changePasswordAction } from "../actions";
import { connect } from "react-redux";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = ({ form: { result } }) => ({
  result
});

/**
 * Map dispatch to props
 */
const mapDispatch = dispatch => ({
  submit: form => dispatch(changePasswordAction(form))
});

/**
 * Compose Higher-order component
 */
export default compose(
  pure,
  connect(mapState, mapDispatch),
  withRefLists("changePassword:")
)(ChangePassword);
