import dataReducers from "./data";
import empty from "empty";
import formReducers from "./form";
import sessionReducers from "./session";
import uiReducers from "./ui";
import { combineReducers } from "redux";

/**
 * Redux reducer object
 * @type {Redux reducer}
 */
const reducer = combineReducers({
  ui: uiReducers,
  data: dataReducers,
  form: formReducers,
  session: sessionReducers,

  /**
   * Error reducer
   * @return {Redux reducer}
   */
  error: (state = empty.object, { payload = empty.object }) =>
    payload.error ? payload : state
});

export default reducer;
