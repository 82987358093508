import { createLogger } from "redux-logger";
import reducer from "./reducers/index";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";

// Middleware
const middleware = [];
if (process.env.NODE_ENV === "development") {
  // dit is het development console voor achter de <F12> toets
  const dev = window.devToolsExtension ? window.devToolsExtension() : f => f;
  middleware.push(compose(applyMiddleware(createLogger()), dev));
}

// add thunk middleware
middleware.push(applyMiddleware(thunkMiddleware));

// de Redux store met de hierboven opgebouwde reducer en 'middleware'
const store = createStore(reducer, ...middleware);

export default store;
