import empty from "empty";
import Form from "./form.jsx";
import React from "react";

/**
 * Register React component
 * @return {React Element}
 */
const Register = ({ submit, result = empty.object, refLists }) => (
  <div>
    <h1>{refLists.getText("Registreren")}</h1>
    {refLists.getDiv("Inleiding registreren")}
    <Form
      submit={submit}
      result={result}
      texts={{
        success: refLists.getText(
          "Uw gegevens zijn opgeslagen, er is een activatielink verzonden naar uw e-mailadres."
        ),
        error: refLists.getText(
          "Er is een fout opgetreden bij het registreren."
        ),
        status: {
          409: refLists.getText(
            "Er bestaat al een account voor dit e-mailadres."
          )
        },
        errors: {
          name: refLists.getText("Dit is een verplicht veld"),
          email: refLists.getText(
            "Dit verplichte veld dient een e-mailadres te bevatten"
          ),
          password: refLists.getText(
            "Het wachtwoord veld moet minimaal 8 karakters langs zijn"
          )
        },
        button: refLists.getText("Registreren")
      }}
    >
      <input
        name="name"
        id="name"
        title={refLists.getText("Naam")}
        type="text"
        required={true}
      />
      <input
        name="email"
        id="email"
        title={refLists.getText("E-mailadres")}
        type="email"
        required={true}
      />
      <input
        name="password"
        id="password"
        title={refLists.getText("Wachtwoord")}
        type="password"
        minLength={8}
        required={true}
      />
    </Form>
  </div>
);

export default Register;
