import empty from "empty";
import { combineReducers } from "redux";

/**
 * User-reducer; this reducers creates data needed for a session
 * @return {Redux reducer}
 */
const userReducer = (state = empty.object, { type, payload }) => {
  switch (type) {
    case "LOGOUT_USER":
      return empty.object;
    case "LOGIN_USER_ERROR":
    case "LOGIN_USER_CHECK_ERROR":
      return { ...state, checked: true, loading: payload.loading };
    case "LOGIN_USER_SUCCESS":
    case "LOGIN_USER_CHECK_SUCCESS":
      return { ...state, checked: true, ...payload.data };
    case "ACTIVATE_USER_SUCCESS":
      return {
        ...state,
        checked: true,
        active: payload.data.active,
        name: payload.data.name
      };
    default:
      return state;
  }
};

/**
 * Session-reducer; this reducer creates all session-data needed for the app
 * @return {Redux reducer}
 */
const sessionReducers = combineReducers({
  user: userReducer
});

export default sessionReducers;
