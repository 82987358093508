import classNames from "classnames";
import compose from "recompose/compose";
import config from "../config";
import empty from "empty";
import mapProps from "recompose/mapProps";
import React from "react";
import withState from "recompose/withState";
import { formatUtcDate } from "../utils";

const ActionButton = ({
  enabled = true,
  active = true,
  symbol,
  className,
  href,
  ...rest
}) =>
  enabled ? (
    <a
      className={classNames("button", { active }, className)}
      href={(active && href) || ""}
      onClick={active && href ? undefined : e => e.preventDefault()}
      {...rest}
    >
      <i className={`fa fa-fw fa-${symbol}`} aria-hidden="true" />
    </a>
  ) : null;

const ActionButtons = compose(
  withState("showConfirm", "toggleConfirm", false),
  mapProps(({ id, showConfirm, toggleConfirm, deleteResult, ...rest }) => ({
    toggle: e => {
      e.preventDefault();
      toggleConfirm(!showConfirm);
    },
    deleteResult: e => {
      e.preventDefault();
      deleteResult(id);
    },
    id,
    showConfirm,
    ...rest
  }))
)(
  ({
    id,
    formId,
    ready,
    uniqueId,
    showConfirm,
    toggle,
    deleteResult,
    refLists,
    buttonsEnabled = {
      pdf: true,
      edit: true,
      delete: true
    },
    forms = empty.object,
    forAdmin = false
  }) => (
    <div>
      <ActionButton
        enabled={buttonsEnabled.pdf}
        active={!forAdmin && ready}
        title={refLists.getText("Bekijk PDF")}
        href={`${config.apiPrefix}/result-${id}.zip`}
        symbol="file-pdf-o"
      />

      <ActionButton
        enabled={buttonsEnabled.edit}
        active={!ready && forms[formId]}
        title={refLists.getText("Bewerken")}
        href={`${config.apiPrefix}/Form.aspx?src=${formId}&restore=${uniqueId}`}
        symbol="pencil"
      />

      <ActionButton
        enabled={buttonsEnabled.delete}
        title={refLists.getText("Verwijderen")}
        onClick={toggle}
        symbol="times"
      />

      {buttonsEnabled.delete && showConfirm && (
        <div className="confirm-dialog">
          <p>
            {refLists.getText("Weet u het zeker?")}
            <br />
            <a href="." onClick={deleteResult}>
              {refLists.getText("Ja")}
            </a>
            <span> - </span>
            <a href="." onClick={toggle}>
              {refLists.getText("Nee")}
            </a>
          </p>
        </div>
      )}
    </div>
  )
);

const WideOverview = ({ results = empty.array, refLists, ...rest }) => (
  <table className="overview">
    <thead>
      <tr>
        <th className="name">{refLists.getText("Naam")}</th>
        <th className="datetime">{refLists.getText("Laatst bewerkt")}</th>
        <th className="status">{refLists.getText("Status")}</th>
        <th className="result">{refLists.getText("Uitkomst")}</th>
        <th className="action-buttons" />
      </tr>
    </thead>
    <tbody className="inspections-table-body">
      {results.map(formResult => (
        <tr key={formResult.id}>
          <td className="name" title={formResult.name}>
            {formResult.name}
          </td>
          <td className="datetime">{formatUtcDate(formResult.updated)}</td>
          <td className="status">{formResult.status}</td>
          <td className="result">{formResult.outcome}</td>
          <td className="action-buttons">
            <ActionButtons refLists={refLists} {...formResult} {...rest} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

const NarrowOverview = ({ results = empty.array, ...rest }) => (
  <div className="overview">
    {results.map(formResult => (
      <div className="overview-row" key={formResult.id}>
        <div className="title">
          <span className="name">{formResult.name}</span>
        </div>
        <div className="details">
          <span className="datetime">{formatUtcDate(formResult.updated)}</span>
          <span className="status">{formResult.status}</span>
          <span className="result">{formResult.outcome}</span>
        </div>
        <div className="action-buttons">
          <ActionButtons {...formResult} {...rest} />
        </div>
      </div>
    ))}
  </div>
);

const Overview = ({ viewport: { width }, results = empty.array, ...rest }) => {
  if (results.length === 0) {
    return null;
  }

  if (width < 991) {
    // narrow viewport
    return <NarrowOverview results={results} {...rest} />;
  }

  // wide viewport
  return <WideOverview results={results} {...rest} />;
};

export default Overview;
