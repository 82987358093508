import compose from "recompose/compose";
import defaultProps from "recompose/defaultProps";
import empty from "empty";
import lifecycle from "recompose/lifecycle";
import Login from "../components/login.jsx";
import pure from "recompose/pure";
import setDisplayName from "recompose/setDisplayName";
import { connect } from "react-redux";
import { hashHistory } from "react-router";
import { loginAction, checkLoginAction } from "../actions";
import { withRefLists } from "../referenceLists.js";

/**
 * Map state to props
 */
const mapState = ({ form: { result }, session: { user } = empty }) => ({
  result,
  user,
  isAuthenticated:
    user !== null &&
    user.id !== null &&
    user !== undefined &&
    user.id !== undefined,
  checked: user.checked || false
});

/**
 * Map dispatch to props
 */
const mapDispatch = dispatch => ({
  check: () => dispatch(checkLoginAction()),
  submit: user => dispatch(loginAction(user))
});

/**
 * Compose Higher-order component
 */
export default compose(
  setDisplayName("Login"),
  pure,
  connect(mapState, mapDispatch),
  defaultProps({
    form: null,
    user: null,
    isAuthenticated: false,
    checked: false
  }),
  withRefLists("login:"),
  lifecycle({
    componentWillMount: function() {
      if (!this.props.isAuthenticated && !this.props.checked) {
        this.props.check();
      } else if (this.props.isAuthenticated && this.props.checked) {
        hashHistory.push(this.props.location.query.next || "/");
      }
    },
    componentWillReceiveProps: function(nextProps) {
      if (nextProps.isAuthenticated && nextProps.checked) {
        hashHistory.push(nextProps.location.query.next || "/");
      }
    }
  })
)(Login);
