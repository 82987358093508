import empty from "empty";
import React from "react";
import { Chart } from "react-google-charts";
import { Link } from "react-router";
import { range } from "../utils";

const Stats = ({
  user = empty.object,
  stats: {
    year,
    complete,
    ready = 0,
    total = 0,
    userStats = empty.object
  } = empty.object,
  refLists
}) => {
  if (!user.isAdmin) {
    return null;
  }

  const colors = ["#ddeff8", "#8fcae7", "#01689b"];

  const dataResults = [["Type", "Aantal"]];

  const inProgress = total - complete - ready;
  if (inProgress > 0) {
    dataResults.push(["In bewerking", inProgress]);
  }

  if (complete >= 0) {
    dataResults.push(["Afgerond", complete]);
  }

  if (ready >= 0) {
    dataResults.push(["Afgesloten", ready]);
  }

  const dataUser = [["Maand", "Geregistreerd", "Geactiveerd", "Inlogs"]];

  const months = [
    "jan",
    "feb",
    "maa",
    "apr",
    "mei",
    "jun",
    "jul",
    "aug",
    "sep",
    "okt",
    "nov",
    "dec"
  ];

  const getStatsForType = (month, type) => {
    if (userStats && userStats.stats[type]) {
      const statsForMonth = userStats.stats[type].find(s => s.month === month);
      return statsForMonth ? statsForMonth.total : 0;
    }

    return 0;
  };

  if (userStats && userStats.stats) {
    range(0, 12).forEach(month => {
      const mIdx = month + 1;
      dataUser.push([
        months[month],
        getStatsForType(mIdx, "register"),
        getStatsForType(mIdx, "activate"),
        getStatsForType(mIdx, "login")
      ]);
    });
  }

  return (
    <div>
      <h1>{refLists.getText("Statistieken")}</h1>
      <div className="stats">
        {userStats && year && (
          <div className="left">
            <h2>
              {refLists.getText("Gebruikers")} in {year}
            </h2>
            {dataResults.length > 1 && (
              <Chart
                chartType="BarChart"
                data={dataUser}
                options={{
                  animation: { startup: true },
                  colors,
                  orientation: "horizontal",
                  theme: "maximized",
                  vAxis: {
                    scaleType: "mirrorLog"
                  }
                }}
              />
            )}
            <ul className="pager">
              <li className="previous">
                <Link to={`/stats/${year - 1}`}>&larr; Vorig jaar</Link>
              </li>
              <li className="next">
                <Link to={`/stats/${year + 1}`}>Volgend jaar &rarr;</Link>
              </li>
            </ul>
          </div>
        )}
        {total > 0 && (
          <div className="right">
            <h2>{refLists.getText("Beoordelingen")}</h2>
            {dataResults.length > 1 && (
              <Chart
                chartType="PieChart"
                data={dataResults}
                options={{
                  chartArea: {
                    left: 0,
                    top: 10,
                    bottom: 10,
                    width: "100%",
                    height: "200"
                  },
                  colors,
                  fontSize: 14,
                  pieHole: 0.5,
                  pieSliceTextStyle: { color: "black" },
                  sliceVisibilityThreshold: 0.03
                }}
              />
            )}
          </div>
        )}
      </div>
      <div className="stats">
        {userStats && year && (
          <div className="left">
            <table className="overview">
              <thead>
                <th>Maand in {year}</th>
                <th>Geregistreerd</th>
                <th>Geactiveerd</th>
                <th>Inlogs</th>
              </thead>
              <tbody>
                {range(0, 12).map(month => {
                  const mIdx = month + 1;
                  return (
                    <tr key={mIdx}>
                      <td>{months[month]}</td>
                      <td>{getStatsForType(mIdx, "register")}</td>
                      <td>{getStatsForType(mIdx, "activate")}</td>
                      <td>{getStatsForType(mIdx, "login")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {total > 0 && (
          <div className="right">
            <table className="overview">
              <thead>
                <th>Status</th>
                <th>Aantal</th>
              </thead>
              <tbody>
                <tr>
                  <td>In bewerking</td>
                  <td>{inProgress}</td>
                </tr>
                <tr>
                  <td>Afgerond</td>
                  <td>{ready}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Stats;
